<template>
  <div class="dashboard">
    <div class="mb-4 pbx-details" v-if="!!currentTenant.hasTenant">
      <h3 class="m-0 mb-2">
        <u>{{ $t('dashboard.pbx-details') }}</u>
      </h3>
      <div class="mb-4 row">
        <div class="col-12 col-lg-6">
          <CallDistribution />
        </div>
        <div class="col-12 col-lg-6">
          <TotalAnsweredCalls />
        </div>
      </div>
      <div class="mb-4 row">
        <div class="col-12 col-lg-6">
          <ExtensionsCount />
        </div>
        <div class="col-12 col-lg-6">
          <OnlineExtensionsCount />
        </div>
      </div>
    </div>
    <div class="service-level-details" v-if="!!currentTenant.hasTenant">
      <div class="mb-4 row">
        <div class="col-12">
          <h3 class="m-0 mb-2">
            <u>{{ $t('dashboard.statistics.service-level-details.title') }}</u>
          </h3>
        </div>
        <div class="col-12 col-lg-6">
          <LiveCallsCount />
        </div>
        <div class="col-12 col-lg-6">
          <AverageCallDuration />
        </div>
        <div class="col-12 col-lg-6">
          <TodayCallDuration />
        </div>
        <div class="col-12 col-lg-6">
          <LastHourCallDuration />
        </div>
      </div>
    </div>
    <div class="billing">
      <div class="mb-4 row">
        <div class="col-12">
          <h3 class="m-0 mb-2">
            <u>{{ $t('dashboard.statistics.billing.title') }}</u>
          </h3>
        </div>
        <div class="col-12 col-lg-6">
          <Balance />
        </div>
        <div class="col-12 col-lg-6">
          <Usage />
        </div>
        <div class="col-12 col-lg-6">
          <OverdueInvoices />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import CallDistribution from '../../components/dashboard/CallDistribution.vue'
import TotalAnsweredCalls from '../../components/dashboard/TotalAnsweredCalls.vue'
import LiveCallsCount from '../../components/dashboard/LiveCallsCount.vue'
import ExtensionsCount from '../../components/dashboard/ExtensionsCount.vue'
import OnlineExtensionsCount from '../../components/dashboard/OnlineExtensionsCount.vue'
import AverageCallDuration from '../../components/dashboard/AverageCallDuration.vue'
import TodayCallDuration from '../../components/dashboard/TodayCallDuration.vue'
import LastHourCallDuration from '../../components/dashboard/LastHourCallDuration.vue'
import Balance from '../../components/dashboard/Balance.vue'
import Usage from '../../components/dashboard/Usage.vue'
import OverdueInvoices from '../../components/dashboard/OverdueInvoices.vue'

let interval

export default {
  name: 'dashboard',
  components: {
    CallDistribution,
    TotalAnsweredCalls,
    LiveCallsCount,
    ExtensionsCount,
    OnlineExtensionsCount,
    AverageCallDuration,
    TodayCallDuration,
    LastHourCallDuration,
    Balance,
    Usage,
    OverdueInvoices
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'currentTenant',
      'callDistributionError',
      'totalAnsweredCallsError',
      'extensionsCountError',
      'onlineExtensionsCountError',
      'liveCallsCountError',
      'averageCallDurationError',
      'todayCallDurationError',
      'lastHourCallDurationError',
      'balanceError',
      'usageError',
      'overdueInvoicesError'
    ])
  },
  methods: {
    ...mapActions([
      'fetchCallDistribution',
      'fetchTotalAnsweredCalls',
      'fetchExtensionsCount',
      'fetchOnlineExtensionsCount',
      'fetchLiveCallsCount',
      'fetchAverageCallDuration',
      'fetchTodayCallDuration',
      'fetchLastHourCallDuration',
      'fetchBalance',
      'fetchUsage',
      'fetchOverdueInvoices'
    ]),
    async initialFetchDashboardStats(silent = false) {
      // limited
      if (!!this.currentTenant.hasTenant) {
        this.fetchCallDistribution({ silent })
        this.fetchTotalAnsweredCalls({ silent })
        this.fetchExtensionsCount({ silent })
        this.fetchOnlineExtensionsCount({ silent })
        this.fetchLiveCallsCount({ silent })
        this.fetchAverageCallDuration({ silent })
        this.fetchTodayCallDuration({ silent })
        this.fetchLastHourCallDuration({ silent })
      }

      // unlimited
      this.fetchBalance({ silent })
      this.fetchUsage({ silent })
      this.fetchOverdueInvoices({ silent })
    },
    async fetchDashboardStats(silent = false) {
      // limited
      if (!!this.currentTenant.hasTenant) {
        this.fetchOnlineExtensionsCount({ silent })
        this.fetchLiveCallsCount({ silent })
        this.fetchTodayCallDuration({ silent })
        this.fetchLastHourCallDuration({ silent })
      }

      // unlimited
      this.fetchBalance({ silent })
    }
  },
  watch: {
    currentTenant() {
      this.initialFetchDashboardStats()
    }
  },
  mounted() {
    interval = setInterval(() => {
      this.fetchDashboardStats(true)
    }, 60000)
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(interval)
    next()
  }
}
</script>

<style lang="scss" scoped></style>
